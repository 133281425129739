<template>
    <div>
        <Navbar v-if="$store.state.user.cliente" page="Seu perfil" />
        <Navbar v-else page="Pessoas na empresa" link="/cadastros" nameLink="Cadastros" video="https://www.youtube.com/watch?v=TtljzS_OSCE" pdf="/itens_exclusivos.pdf" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="flex mb-5">
                <h1 class="text-2xl mt-2 ml-2">
                    {{ $store.state.user.cliente ? 'Seu perfil' : 'Pessoas na empresa' }}
                </h1>
            </div>
            
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-4">
                                <label for="nome" class="block text-sm font-medium">Nome Completo *</label>
                                <input v-model="form.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                        </div>
                    </div>
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-6">
                                <label for="email" class="block text-sm font-medium">Email *</label>
                                <input v-model="form.email" type="email" name="email" id="email" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div v-if="!alterarPassword" class="col-span-12 md:col-span-4">
                                <button @click="alterarPassword = !alterarPassword" type="button" class="inline-flex justify-center py-2 px-6 md:mt-7 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-500">
                                    Alterar password
                                </button>
                            </div>
                            <div v-if="alterarPassword" class="col-span-12 md:col-span-4">
                                <label for="password" class="block text-sm font-medium">Senha *</label>
                                <input v-model="form.password" type="password" name="password" id="password" autocomplete="off" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <label for="telefone" class="block text-sm font-medium">Telefone</label>
                                <input v-model="form.telefone" type="text" name="telefone" id="telefone" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div v-if="!$store.state.user.cliente" class="col-span-12 md:col-span-4">
                                <label for="setor" class="block text-sm font-medium">Setor</label>
                                <select required v-model="form.setor" name="setor" id="setor" class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="setor in setores" :key="setor._id" :value="setor._id">{{ setor.nome }}</option>
                                </select>
                            </div>
                            <div v-if="!$store.state.user.cliente" class="col-span-12 md:col-span-4">
                                <label for="funcao" class="block text-sm font-medium">Função</label>
                                <select required v-model="form.funcao" name="funcao" id="funcao" class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="funcao in funcoes" :key="funcao._id" :value="funcao._id">{{ funcao.nome }}</option>
                                </select>
                            </div> 
                            <div v-if="!$store.state.user.cliente" class="col-span-12 md:col-span-12">
                                <label for="receberEmail" class="block text-sm font-medium">
                                    <input type="checkbox" class="rounded-sm" id="receberEmail" v-model="form.receberEmail">
                                    <span class="ml-2"> Receber e-mail</span>
                                </label>
                            </div>
                            <div v-if="!$store.state.user.cliente" class="col-span-12 md:col-span-12">
                                <label for="timePrivacidade" class="block text-sm font-medium">
                                    <input type="checkbox" class="rounded-sm" id="timePrivacidade" v-model="form.timePrivacidade">
                                    <span class="ml-2"> Faz parte do time de privacidade </span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="px-5 pb-4 mt-3">
                        <label for="telefone" class="block text-sm font-medium">Foto Perfil</label>
                        <small class="text-xs font-medium">Tamanho recomendado 512x512</small>
                        <div class="grid grid-cols-12 gap-6 mt-3">
                            <div class="col-span-12 md:col-span-4">
                                <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="updateFoto">
                                </vue-dropzone>
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <img class="w-32 h-32 rounded-full object-cover" v-if="this.form.foto && this.form.foto.filename" :src="`${this.url_api}/upload?mimetype=image/jpeg&filename=${this.form.foto.filename}&folder=empresas`"/>
                            </div>
                        </div>
                    </div>
                    
                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    components: {
        vueDropzone
    },
    data() {
        return {
            route: 'pessoas',
            alterarPassword: this.$route.params.id ? false : true,
            form: {
                nome: '',
                setor: null,
                email: '',
                telefone: '',
                password: '',
                funcao: null,
                receberEmail: true,
                timePrivacidade: false,
                foto: null
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/upload`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste aqui',
                dictRemoveFile: 'Remover'
            }),
            setores: [],
            funcoes: [],
        }
    },
    methods: {
        async save() {
            const method = this.form._id ? 'put' : 'post'

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        updateFoto(file, response) {
            this.form.foto = response.file;
        },
    },
    async beforeMount() {
        const id = this.$route.params.id;

        const setReq = await this.$http.post(`/v1/setores/list`, {all: true});
        const funcReq = await this.$http.post(`/v1/funcoes/list`, {all: true});

        this.setores = setReq.data.data;
        this.funcoes = funcReq.data.data;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
        }
    },
}
</script>